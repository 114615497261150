import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const HomeBannerImage = ({mobileImg}) => {

    return (
      <>
        {mobileImg ?
          <StaticImage
            fetchpriority="high"
            src="../../images/home_mobile.jpg"
            layout="fullWidth"
            placeholder="blurred"
            formats={["webp"]}
            alt="banner"
            quality={70}
            className="img-fluid banner-img d-block d-md-none"
            loading="eager"
          />
          :
          <StaticImage
            fetchpriority="high"
            src="../../images/home_banner.jpg"
            layout="fullWidth"
            placeholder="blurred"
            formats={["webp"]}
            alt="banner"
            quality={70}
            className="img-fluid banner-img d-block d-md-none"
            loading="eager"
          />
        }
      </>
        
    )

}

export default HomeBannerImage
